import type { BoxProps } from '@chakra-ui/react'
import { Box, Button, Flex, Icon, SimpleGrid } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GameGridEmpty } from '@/modules/my-games/GamesGrid'
import { Icon as SharedIcon } from '@/utils/atom-shared'

import type { GameListItem } from '../../gameInterface'
import type { Orientation } from '../../units/gamecard/AtomGameCard'
import { GRID_COLUMNS, GRID_MAX_SIZE } from '../constants'
import GameCardRender from '../game-list/GameCardRender'

export function GameGrid(
  props: BoxProps & {
    data: GameListItem[]
    pageSize?: number
    onCardClick?: (game: GameListItem) => void
    coverOrientation?: Orientation
    emptyProps?: {
      onButtonClick?: () => void
      emptyText?: string
    }
  },
) {
  const {
    data,
    emptyProps,
    onCardClick,
    pageSize = GRID_MAX_SIZE,
    coverOrientation = 'portrait',
    ...restProps
  } = props
  const [page, setPage] = useState(1)
  const { t } = useTranslation()

  useEffect(() => {
    setPage(1)
  }, [data])

  const totalPage = useMemo(() => {
    return Math.ceil(data.length / pageSize)
  }, [data.length, pageSize])

  const dataSlice = data.slice(0, pageSize * page)

  const onLoadMore = async () => {
    if (page < totalPage) {
      setPage(page + 1)
    }
  }

  if (emptyProps && data?.length === 0) {
    return <GameGridEmpty emptyText={emptyProps.emptyText} />
  }

  return (
    <>
      <Box {...restProps}>
        <SimpleGrid columns={GRID_COLUMNS} columnGap='3' rowGap='3'>
          {dataSlice.map(item => (
            <GameCardRender
              key={item.game_id + item.game_name}
              info={item}
              onClick={onCardClick?.bind(null, item)}
              orientation={coverOrientation}
            />
          ))}
        </SimpleGrid>
      </Box>
      {page < totalPage && (
        <Flex justifyContent={'center'}>
          <Button size='mdsm' onClick={onLoadMore} colorScheme='white'>
            {t('HOME_LOAD_MORE')}
            <Icon as={SharedIcon.More} boxSize='5' ml='1' />
          </Button>
        </Flex>
      )}
    </>
  )
}
