import React from 'react'

import { useAppSelector } from '@/modules/app/store'

import { selectAllCateByLableId } from '../../gameSlice'
import type { Orientation } from '../../units/gamecard/AtomGameCard'
import { trackHomeToGameEvt } from '../trackHomeToGameEvt'
import { GameGridWithLoadMoreMemo } from './GameGridWithLoadMoreMemo'

function CategoriedGameGrid(props: {
  categroyId: number
  coverOrientation?: Orientation
}) {
  const { categroyId } = props
  const allCateByLabelId = useAppSelector(selectAllCateByLableId)

  return (
    <GameGridWithLoadMoreMemo
      coverOrientation={props?.coverOrientation || 'landscape'}
      list={allCateByLabelId[categroyId]?.data}
      onItemClick={trackHomeToGameEvt}
      viewAllHref={`/game/all?cate=${categroyId}&label=-1`}
    />
  )
}
export const CategoriedGameGridMemo = React.memo(CategoriedGameGrid)
