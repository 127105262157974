import type { ComponentStyleConfig } from '@chakra-ui/react'

import {
  NAME,
  PARTS,
} from '@/modules/inhouse/common/components/bet-button/BetButton'

import theme from './inhouse'

const { buttonNormalBg, buttonNormalColor } = theme
const parts = [...PARTS]
const componentStyle: ComponentStyleConfig = {
  parts,
  baseStyle: {
    box: {
      bg: buttonNormalBg,
      _disabled: {
        bgColor: 'gray.600',
        color: 'white',
      },
    },
    maintext: {
      color: buttonNormalColor,
    },
  },
  variants: {
    blue: {
      box: {
        bg: 'prim.500',
      },
    },
    green: {
      box: {
        bg: '#00AC41',
      },
    },
    red: {
      box: {
        bg: 'seco.500',
      },
    },
  },
}

export const name = NAME
export default componentStyle
