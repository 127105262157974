import { lazy } from 'react'
import type { RouteObject } from 'react-router-dom'

import { CollectionGamesProvider } from '@/modules/my-games/useGameGrid'

const AsyncGameList = lazy(() => import('@/modules/game/logic/all-games/AllGame'))
const AsyncInhouse = lazy(() => import('@/modules/inhouse'))

const AsyncSlot = lazy(() => import('@/pages/slot-and-live'))

const AsyncThirdGame = lazy(
  () => import('@/modules/game/third-game/ThirdPartGameWrap'),
)

export const gameRouter: RouteObject[] = [
  {
    path: '/game',
    children: [
      {
        // inhouse游戏
        path: '/game/inhouse/:gameId',
        element: (
          <CollectionGamesProvider>
            <AsyncInhouse />
          </CollectionGamesProvider>
        ),
      },
      {
        // slot/live游戏
        path: '/game/:gameType/:gameId',
        element: (
          <CollectionGamesProvider>
            <AsyncThirdGame />
          </CollectionGamesProvider>
        ),
      },

      {
        // 游戏列表
        path: '/game/all',
        element: (
          <CollectionGamesProvider>
            <AsyncGameList />
          </CollectionGamesProvider>
        ),
      },
    ],
  },
  {
    // slot/live游戏
    path: '/live',
    element: (
      <CollectionGamesProvider>
        <AsyncSlot />
      </CollectionGamesProvider>
    ),
  },
]
