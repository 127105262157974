import { isProduction } from '@/common/env'

export default function atomGtag(...args: any) {
  if (isProduction()) {
    if (typeof gtag === 'function') {
      gtag(...args)
    }
  } else {
    console.log('gtag')
    console.log('gtag', ...args)
  }
}
