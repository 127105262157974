import format from 'string-template'

import property from '@/websites/current/property.json'

type Mode = 'prod' | 'preprod' | 'staging' | 'dev'
/** 判断是否是生产环境 */
export function isProduction(): boolean {
  return import.meta.env.MODE === 'prod' || import.meta.env.MODE === 'preprod'
}

/** 判断是否是生产环境 */
export function isStaging(): boolean {
  return import.meta.env.MODE === 'staging'
}

export function getMode(): Mode {
  return import.meta.env.MODE as Mode
}

/** 判断是前端生产环境 */
export function isFEProduction(): boolean {
  return import.meta.env.MODE === 'prod'
}

//判断办公网 兼容线上接口用get 办公网用get
export function isNotOffice(): boolean {
  return import.meta.env.MODE !== 'dev'
}

export function isNotLocal(): boolean {
  // 如果地址是192.168.*.* 或者 localhost 或者 127.0.0.1
  // 则认为是本地环境
  return !/^(192\.168|localhost|127\.0\.0\.1)/.test(location.host)
}

/** 获取配置变量 */
export function getVariable(name: string): string | string[] {
  return (property.variables as unknown as Record<string, string>)[name]
}

function getUrlParams() {
  const hostdomain = location.host.split('.')
  const host2 = hostdomain.slice(-3)
  if (host2?.[0] === 'www') {
    host2.shift()
  }
  return {
    host: location.host,
    host2: host2.join('.'),
    origin: location.origin,
    protocol: location.protocol,
    href: location.href,
  }
}

export function getApiBaseUrl(): string {
  return format(import.meta.env.VITE_API_BASE_URL, getUrlParams())
}

/**
 * 由服务器端生成的配置文件
 * @param currency 如果要区分币种，则要传入币种参数（需要配置本身也区分币种）
 */
export function getConfigBaseUrl(currency?: string): string {
  const base = format(import.meta.env.VITE_CONFIG_BASE_URL, getUrlParams())
  if (currency) {
    return [base, currency].join('/')
  } else {
    return base
  }
}

/**
 * 由服务器端生成的配置文件
 * @param currency 如果要区分币种，则要传入币种参数（需要配置本身也区分币种）
 * @description 曾用名：getModalConfigBaseUrl，由于功能扩展，现在改为getPageConfigBaseUrl
 */
export function getPageConfigBaseUrl(currency?: string): string {
  const base = format(import.meta.env.VITE_MODAL_JSON_URL, getUrlParams())
  if (currency) {
    return [base, currency].join('/')
  } else {
    return base
  }
}

/**
 * 暂时没有走服务器配置的配置文件
 * @param currency 如果要区分币种，则要传入币种参数（需要配置本身也区分币种）
 */
export function getLocalConfigBaseUrl(currency?: string): string {
  const base = format(
    import.meta.env.VITE_LOCAL_CONFIG_BASE_URL,
    getUrlParams(),
  )
  if (currency) {
    return [base, currency].join('/')
  } else {
    return base
  }
}

export function getWssBaseUrl(): string {
  return format(import.meta.env.VITE_WSS_BASE_URL, getUrlParams())
}

export function getGameBaseUrl(): string {
  return format(import.meta.env.VITE_GAME_BASE_URL, getUrlParams())
}

//fundist中转页面
export function getFundistPageUrl(): string {
  return format(import.meta.env.VITE_FUNDIST_IFRAME_ULR, getUrlParams())
}

//gmp-fundist中转页面
export function getGmpFundistPageUrl(): string {
  return format(import.meta.env.VITE_GMP_FUNDIST_IFRAME_ULR, getUrlParams())
}

// modal信息api
export function getModalCfgUrl(): string {
  return format(import.meta.env.VITE_MODAL_JSON_URL, getUrlParams())
}

//pgsoft中转页面
export function getPgSoftPageUrl(): string {
  return format(`${location.origin}/atom_pgsoft.html`, getUrlParams())
}
