import {
  Box,
  Skeleton,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'

import { useAppSelector } from '@/modules/app/store'
import { HEADER_HEIGHT } from '@/modules/app-layout/constants'
import { HOME_GAME_ANCHOR_ELEMENT_ID } from '@/utils/hooks/useNavToHomeGame'

import { selectGameTabItems } from '../../selectGameTabItems'
import GameCatagoryTabs from '../../units/GameCatagoryTabs'
import RecommendGameGroups from '../game-list/recommend-game-groups/RecommendGameGroups'
import { CategoriedGameGridMemo } from './CategoriedGameGridMemo'
import { FavoritedGameGridMemo } from './FavoritedGameGridMemo'
import { ManufGameGridMemo } from './ManufGameGridMemo'
import { LabelChecker } from './utils'

function HomeGames() {
  const [activedTabIndex, setActivedTabIndex] = useState(0)
  const gameTabItems = useAppSelector(selectGameTabItems)
  const setTabToDefault = useCallback(() => setActivedTabIndex(0), [])

  const tabContentRender = useCallback(
    (item: (typeof gameTabItems)[number]) => {
      if (LabelChecker.isRecommandGroups(item.id)) {
        return <RecommendGameGroups />
      } else if (LabelChecker.isProviderGroups(item.id)) {
        return <ManufGameGridMemo coverOrientation={item.coverOrientation} />
      } else if (LabelChecker.isFavorite(item.id)) {
        return <FavoritedGameGridMemo goAlternate={setTabToDefault} />
      } else {
        return (
          <CategoriedGameGridMemo
            categroyId={item.id}
            coverOrientation={item.coverOrientation}
          />
        )
      }
    },
    [setTabToDefault],
  )

  // if (gameTabItems?.length === 0) return null

  return (
    <Tabs
      isLazy
      lazyBehavior='keepMounted'
      variant='game'
      colorScheme='game'
      index={activedTabIndex}
      onChange={setActivedTabIndex}
      position='relative'
    >
      <Box
        id={HOME_GAME_ANCHOR_ELEMENT_ID}
        position='absolute'
        top={`-${HEADER_HEIGHT}`}
      />

      <Skeleton
        minH='52px'
        isLoaded={gameTabItems?.length > 0}
        fadeDuration={1}
        minW={'100%'}
      >
        <GameCatagoryTabs items={gameTabItems} />
      </Skeleton>
      <Skeleton
        minH='156px'
        isLoaded={gameTabItems?.length > 0}
        fadeDuration={1}
        minW={'100%'}
      >
        <TabPanels mt='5'>
          {gameTabItems.map(item => (
            <TabPanel p='0' key={item.id}>
              <VStack id='game-list' alignItems='stretch' spacing='5'>
                {tabContentRender(item)}
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Skeleton>
    </Tabs>
  )
}

export default HomeGames
