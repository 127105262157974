enum GAEvent {
  REGISTER_BTN_CLICK = 'register_btn_click',
  REGISTER_GGBTN_CLICK = 'register_ggbtn_click',
  REGISTER_FBBTN_CLICK = 'register_fbbtn_click',
  REGISTER_TGBTN_CLICK = 'register_tgbtn_click',

  LOGIN_BTN_CLICK = 'login_btn_click',
  LOGIN_GGBTN_CLICK = 'login_ggbtn_click',
  LOGIN_FBBTN_CLICK = 'login_fbbtn_click',
  LOGIN_TGBTN_CLICK = 'login_tgbtn_click',
}

export default GAEvent
