import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME, PARTS } from '@/components/atom-nav-accordion-item/constants'

const componentStyle: ComponentStyleConfig = {
  parts: PARTS as unknown as string[],
  baseStyle: {},
}

export const name = NAME
export default componentStyle
