import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME, PARTS } from '@/components/atom-active-link/constants'

const componentStyle: ComponentStyleConfig = {
  parts: PARTS as unknown as string[],
  baseStyle: {
    container: {
      '--icon-color': 'var(--chakra-colors-text-base)',
      _activeLink: {
        bgGradient: 'none',
        bg: 'seco.500',
        color: 'white',
        '--icon-color': 'white',
      },
    },
  },
  variants: {
    secondary: {
      container: {
        _activeLink: {
          bgGradient: 'none',
          bg: 'prim.500',
        },
      },
    },
  },
}

export const name = NAME
export default componentStyle
