import type { ProjectConfig } from '@/types/project-config/ProjectConfig'

const config: ProjectConfig = {
  emailSuffixs: [
    '@gmail.com',
    '@yahoo.com',
    '@icloud.com',
    '@outlook.com',
    '@hotmail.com',
    '@aol.com',
    '@ph.com',
    '@globe.com.ph',
    '@smart.com.ph',
    '@pldt.com.ph',
    '@sky.com.ph',
  ],
}

export default config
