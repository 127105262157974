import { Box, Button, HStack, Icon, IconButton, Text } from '@chakra-ui/react'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import glowSVG from '@/assets/images/glow.svg'
import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { useAppDispatch, useAppSelector } from '@/modules/app/store'
import { HomePageConfigContext } from '@/modules/app-layout/AppLayout'
import {
  getIsBindCurrency,
  setCallBackType,
  switchBindModal,
} from '@/modules/bind-currency/currencySlice'
import { selectAgentRechargeRebate } from '@/modules/referral/referralSlice'
import { selectUserIsLogin } from '@/modules/user/userSlice'
import { Icon as SharedIcon } from '@/utils/atom-shared'
import { useCustomizedStyle } from '@/utils/hooks'
import { useActionTrigger } from '@/utils/hooks/useActionTrigger'
import { getConfigLng } from '@/utils/tools'

import type { topPromotionsConfig } from '../app/home-page-config-types'
import {
  closeDrawerStart,
  createDrawerOpenSelector,
  DrawerKey,
  drawerTransitionEnd,
  openDrawerStart,
} from './app-layout-slice'

const SHOW_TOP_BANNER = getVariable('SHOW_TOP_BANNER')
export const STORAGE_KEY = 'HAS_TOP_BANNER_CLOSED'
const isBottomRecommend = isFeatureEnabled('bottomRecommend')

const useAutoOpen = (enabled = true) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (enabled && !sessionStorage.getItem(STORAGE_KEY)) {
      dispatch(openDrawerStart(DrawerKey.Top))
    }
  }, [dispatch, enabled])
}

/**
 * 处理\n不换行问题
 * @param content
 */
const hanldeContent = (content: string) => {
  content = content || ''
  content = content.replace(/\n/g, '<br>')
  return content
}

const lng = getConfigLng()

export function TopBanner() {
  const rechargeRebate = useAppSelector(selectAgentRechargeRebate)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLogin = useAppSelector(selectUserIsLogin)
  const isBindCurrency = useAppSelector(getIsBindCurrency)
  const SHOW_BIND_SELECT = getVariable('SHOW_BIND_SELECT')
  function useHomePageConfigTopPromotion() {
    const homePageConfig = useContext(HomePageConfigContext)
    return (homePageConfig?.topPromotions || {}) as topPromotionsConfig
  }
  const topPromotions = useHomePageConfigTopPromotion()
  const actionTrigger = useActionTrigger()

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

  const BoxHeight = useMemo(() => {
    // if (isIOS) {
    //   return `calc(${HEADER_HEIGHT} + env(safe-area-inset-top))`
    // }
    return 'unset'
  }, [isIOS])

  const styles = useCustomizedStyle(
    'TopBanner',
    {
      card: {
        w: 'full',
        p: '4',
        py: { base: '3', md: '5' },
        borderRadius: { base: '0', md: 'lg' },
        isolation: 'isolate',
        _before: {
          display: { base: 'none', md: 'block' },
          content: '""',
          position: 'absolute',
          zIndex: '-1',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          bg: `no-repeat center bottom / 100% auto url(${glowSVG})`,
        },
      },
    },
    ['card', 'button'],
  )
  useAutoOpen((rechargeRebate !== 0 && !isBottomRecommend) || isBottomRecommend)

  const isTopDrawerOpen = useAppSelector(
    createDrawerOpenSelector(DrawerKey.Top),
  )

  useEffect(() => {
    if (isBottomRecommend && sessionStorage.getItem(STORAGE_KEY) !== '2') {
      dispatch(openDrawerStart(DrawerKey.Top))
    }
  }, [isLogin])

  if ((rechargeRebate === 0 || !SHOW_TOP_BANNER) && !isBottomRecommend) {
    return null
  }

  const highlightStyles = {
    fontWeight: 'bold',
    color: 'four.400',
    fontSize: 'sm',
  }

  const handleClose = (clickClose?: boolean) => {
    if (
      (isBottomRecommend && topPromotions?.show_rule === 0) ||
      !isBottomRecommend ||
      !!clickClose
    ) {
      const value = !!clickClose || !isBottomRecommend ? '1' : '2'
      sessionStorage.setItem(STORAGE_KEY, value)
    }
    if (isBottomRecommend && topPromotions?.show_rule === 1 && !clickClose)
      return
    dispatch(closeDrawerStart(DrawerKey.Top))
  }

  const handleActionBtnClick = () => {
    if (!isBindCurrency && SHOW_BIND_SELECT) {
      dispatch(
        setCallBackType({
          callBackType: 'href_/referral',
        }),
      )
      dispatch(switchBindModal())
      return
    }
    handleClose()
    navigate('/referral')
  }

  const handleTopBannerTranslationEnd = () => {
    dispatch(drawerTransitionEnd(DrawerKey.Top))
  }

  if (isBottomRecommend) {
    // 后台配置
    const action = topPromotions?.action || {}
    const language_content = topPromotions?.language_content?.[lng] || {}
    const content = language_content?.promotion_text || ''
    const btn_text = language_content?.btn_text || ''

    if (!topPromotions?.id) return null
    return (
      <Box
        overflow='hidden'
        w={{ base: 'full', md: '80' }}
        position={{ base: 'relative', md: 'fixed' }}
        // h={`calc(${HEADER_HEIGHT} + env(safe-area-inset-top))`}
        h={BoxHeight}
        right={{ base: '0', md: '12' }}
        zIndex='banner'
        maxH={isTopDrawerOpen ? '500px' : '0px'}
        transition='all 0.3s'
        onTransitionEnd={handleTopBannerTranslationEnd}
      >
        <HStack sx={styles.card}>
          <HStack
            flex='1'
            justifyContent={['flex-start', 'flex-start', 'center']}
            spacing={3}
          >
            <Text
              textAlign='left'
              textStyle='text6'
              flex='1'
              noOfLines={3}
              dangerouslySetInnerHTML={{
                __html: hanldeContent(content || ''),
              }}
            />
            <Button
              colorScheme='fill-prim'
              variant='solid'
              size='md'
              sx={styles.button}
              onClick={actionTrigger(action, handleClose)}
              fontSize='12px'
            >
              <Text noOfLines={1} maxW={'90px'}>
                {btn_text}
              </Text>
            </Button>
          </HStack>
          <IconButton
            top='1'
            right='1'
            aria-label='Close'
            variant='unstyledIcon'
            icon={
              <Icon
                as={SharedIcon.Close}
                boxSize='17.15px'
                color='text.secondary'
              />
            }
            onClick={() => {
              handleClose(true)
            }}
            position={{ base: 'static', md: 'absolute' }}
          />
        </HStack>
      </Box>
    )
  }
}
