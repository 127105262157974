import { createSelector } from '@reduxjs/toolkit'
import type { FC } from 'react'

import { FeatureToggle, isFeatureEnabled } from '@/common/feature'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
import GlobalLoading from '@/components/global-loading/GlobalLoading'
import BindCurrency from '@/modules/bind-currency/bindCurrency'
import GoogleRecaptchaModal from '@/modules/google-recaptcha/GoogleRecaptchaModal'
import ParamsModalControl from '@/modules/wallet/ParamsModalControl'
import VirtualModal from '@/modules/wallet/virtualModal'
import WalletModal from '@/modules/wallet/walletModal'

import ActTips from '../activity/ActTips'
import BankruptRechargeModal from '../activity/bankrupt-recharge/BankruptRechargeModal'
import DrawActivityModal from '../activity/draw-activity/DrawActivityModal'
import LuckyWheelModal from '../activity/lucky-wheel/LuckyWheelModal'
import SharedModal from '../activity/lucky-wheel/SharedModal'
import MoneyRainModal from '../activity/money-rain/MoneyRainModal'
import RechargeActModal from '../activity/RechargeActModal'
import RechargePackageModal from '../activity/RechargePackageModal'
import AnnounceModal from '../announce-modal/AnnounceModal'
import AddToHomeScreenModal from '../app/add-to-home-screen-modal'
import { useAppSelector } from '../app/store'
import BindPhoneModal from '../bind-phone/bindPhoneModal'
import ConfirmModal from '../confirm-modal/ConfirmModal'
import GameUpdateTips from '../game/updateModal'
import AppDownloadDiversionModal from '../guide-event/AppDownloadDiversionModal'
import IntroductionToDownloadModal from '../guide-event/IntroductionToDownloadModal'
import IntroductionToDownloadModalNew from '../guide-event/IntroductionToDownloadModalNew'
import IOSSecurityAwarenessModal from '../guide-event/IOSSecurityAwarenessModal'
import QrCodeModal from '../guide-event/QrCodeModal'
import RechargeComfirmModal from '../inhouse/logic/RechargeComfirmModal'
import LoginAndSignUpProvider from '../login/LoginAndSignUpProvider'
import LoginAndSignUpConfigErrorModal from '../login/New/components/LoginAndSignUpConfigErrorModal'
import WrongPasswordModal from '../login/New/components/WrongPassword'
import ResetPwdDialog from '../login/ResetPwdDialog'
import LoginRewardModal from '../login-reward-modal/LoginRewardModal'
import NewLoginReward from '../login-reward-modal/newLoginRewardModal'
import RechargeGuideModal from '../login-reward-modal/RechargeGuideModal'
import FavoriteLimitModal from '../my-games/FavoriteLimitModal'
import BindInfoModal from '../new-login/components/BindInfoModal'
import NewLoginRewardModal from '../new-vip/loginRewardModal'
import SuccessModal from '../new-vip/SuccessModal'
import CouponModal from '../promotional-event/CouponModal'
import SplashAdsModal from '../promotional-event/SplashAdsModal'
import RechargeActivity from '../recharge-activity/rechargeActivity'
import RechargeActTips from '../recharge-activity/tipsModal'
import RedeemResultModal from '../redeem-result-modal/RedeemResultModal'
import RegionLimitModal from '../region-limit-modal/RegionLimitModal'
import Search from '../search-game/Search'
import ServiceList from '../service-list/ServiceList'
import TgModal from '../tg-modal/TgModal'
import VipUpgradeGuide from '../vip/components/VipUpgradeGuide'
import FullPageLoading from '../wallet/fullPageLoading'
import GcashModal from '../wallet/GcashModal'
import HomeVirtualList from '../wallet/homeVirtualList'
import MPesaModal from '../wallet/MPesaModal'
import { PixQrcodeModal } from '../wallet/pixQrcodeModal'
import WalletConfirmFormModal from '../wallet/WalletConfirmFormModal'
import WalletGiftTipsModal from '../wallet/walletGiftTipsModal'
import { WalletTutorialModal } from '../wallet/walletTutorialModal'
import WheelModal from '../wheel-modal/wheelModal'
import { selectModal } from './globalModalSlice'
import { GlobalModalKey } from './types'

const newAppLoginReward = isFeatureEnabled('newAppLoginReward')

export default function GlobalModal() {
  return (
    <>
      {/* 未优化 */}
      <FeatureToggle feature='gameSearch'>
        <NiceGlobalModal name={GlobalModalKey.Search} component={<Search />} />
      </FeatureToggle>

      <NiceGlobalModal
        name={GlobalModalKey.SplashAds}
        component={<SplashAdsModal />}
      />
      {/* 提现打码量提示 */}
      <NiceGlobalModal
        name={GlobalModalKey.WalletGiftTips}
        component={<WalletGiftTipsModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.Login}
        component={<LoginAndSignUpProvider />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.ResetPassword}
        component={<ResetPwdDialog />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.ServiceList}
        component={<ServiceList />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.Coupon}
        component={<CouponModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.LuckyWheel}
        component={<WheelModal />}
      />
      {/* <LoginRewardModal /> */}

      {/** new login sub reward modal */}
      <NiceGlobalModal
        name={GlobalModalKey.newLoginRewardModal}
        component={<NewLoginRewardModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.RewardSuccess}
        component={<SuccessModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.RechargeGuide}
        component={<RechargeGuideModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.RegionLimit}
        component={<RegionLimitModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.Confirm}
        component={<ConfirmModal />}
      />
      {/* 引流下载app弹窗 */}
      <NiceGlobalModal
        name={GlobalModalKey.AppDownloadDiversion}
        component={<AppDownloadDiversionModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.AddToHomeScreen}
        component={<AddToHomeScreenModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.IntroductionToDownloadModal}
        component={
          newAppLoginReward ? (
            <IntroductionToDownloadModalNew />
          ) : (
            <IntroductionToDownloadModal />
          )
        }
      />

      <NiceGlobalModal
        name={GlobalModalKey.AddToHomeScreen}
        component={<AddToHomeScreenModal />}
      />

      {/** 充值礼包弹窗 */}
      <NiceGlobalModal
        name={GlobalModalKey.RechargePackage}
        component={<RechargePackageModal />}
      />

      {/** 活动tips弹窗 */}
      <NiceGlobalModal name={GlobalModalKey.ActTips} component={<ActTips />} />
      {/** 新的充值活动 */}
      <NiceGlobalModal
        name={GlobalModalKey.newRechargeActModal}
        component={<RechargeActModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.WrongPassword}
        component={<WrongPasswordModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.IOSSecurityAwareness}
        component={<IOSSecurityAwarenessModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.QrCode}
        component={<QrCodeModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.LoginAndSignUpConfigError}
        component={<LoginAndSignUpConfigErrorModal />}
      />

      {/* 等人认领优化代码 */}
      <HomeVirtualList />
      {/* 等人认领优化代码 */}
      <BindCurrency />
      <GameUpdateTips />
      {/* 未优化 */}
      <NiceGlobalModal name={GlobalModalKey.TgModal} component={<TgModal />} />
      {/* 等人认领优化代码 */}
      <NiceGlobalModal
        name={GlobalModalKey.Wallet}
        component={<WalletModal />}
      />
      {/* 充值提现表单 */}
      <ParamsModalControl />

      {/* 等人认领优化代码 */}
      <VirtualModal />
      {/* 未优化 兑换券领取成功tips */}
      <RedeemResultModal />
      <AnnounceModal />
      <BindPhoneModal />
      {/* 充值活动 */}
      <RechargeActivity name={GlobalModalKey.RechargeActivity} />
      {/* 破产充值弹窗 */}
      <RechargeComfirmModal />

      {/* pix支付弹出层 */}
      <PixQrcodeModal />
      {/* 充值活动提示 */}
      <RechargeActTips />

      {/* b3转盘裂变 */}
      <NiceGlobalModal
        name={GlobalModalKey.LuckyWheelActivity}
        component={<LuckyWheelModal />}
      />

      {/* recaptcha验证弹窗 */}
      <NiceGlobalModal
        name={GlobalModalKey.GoogleRecpatcha}
        component={<GoogleRecaptchaModal />}
      />

      {/* 现金雨 */}
      <NiceGlobalModal
        name={GlobalModalKey.MoneyRain}
        component={<MoneyRainModal />}
      />

      {/*  */}
      <FullPageLoading />

      <GlobalLoading />

      {/**
       * 分享弹窗
       */}
      <NiceGlobalModal
        name={GlobalModalKey.SharesModal}
        component={<SharedModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.FavoriteLimit}
        component={<FavoriteLimitModal />}
      />
      {/** Mpesa充值渠道弹窗 */}
      <NiceGlobalModal
        name={GlobalModalKey.MPesaModal}
        component={<MPesaModal />}
      />
      {/** 新版绑定信息弹窗 */}
      <NiceGlobalModal
        name={GlobalModalKey.BindInfoModal}
        component={<BindInfoModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.GcashPaymentModal}
        component={<GcashModal />}
      />

      {/** 破产充值V3 */}
      <NiceGlobalModal
        name={GlobalModalKey.BankruptRechargeModal}
        component={<BankruptRechargeModal />}
      />
      <NiceGlobalModal
        name={GlobalModalKey.DrawActivityModal}
        component={<DrawActivityModal />}
      />

      {/** 破产充值V3 */}
      <NiceGlobalModal
        name={GlobalModalKey.WalletTutorial}
        component={<WalletTutorialModal />}
      />

      {/** new login reward modal */}
      {/** NewLoginReward is modal 7days*/}
      {/** NewLoginRewardModal is sub modal of modal 7days*/}
      <NiceGlobalModal
        name={GlobalModalKey.LoginReward}
        component={
          isFeatureEnabledV2('enable_7days_opt') ? (
            <NewLoginReward />
          ) : (
            <LoginRewardModal />
          )
        }
      />

      {/** 充提表单 */}
      <NiceGlobalModal
        name={GlobalModalKey.WalletConfirmForm}
        component={<WalletConfirmFormModal />}
      />

      <NiceGlobalModal
        name={GlobalModalKey.UpgradeVipGuide}
        component={<VipUpgradeGuide />}
      />
    </>
  )
}

interface NiceGlobalModalProps {
  name: GlobalModalKey
  component: React.ReactNode
}

const NiceGlobalModal: FC<NiceGlobalModalProps> = ({ name, component }) => {
  const { open } = useAppSelector(
    createSelector(selectModal, globalModal => globalModal[name]),
  )

  if (!open) return null

  return component
}
