import type { TableProps, ThemingProps } from '@chakra-ui/react'
import {
  Box,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDimensions,
} from '@chakra-ui/react'
import type { ColumnDef } from '@tanstack/react-table'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useRef } from 'react'

import AtomEmpty from '../atom-empty/AtomEmpty'

export type DataTableProps<Data extends object, Value = unknown> = {
  data: Data[]
  columns: ColumnDef<Data, Value>[]
  onRowClick?(
    row: Data,
    index: number,
    e: React.MouseEvent<HTMLTableRowElement>,
  ): void
  isLoading?: boolean
  variant?: ThemingProps<'Table'>['variant']
  size?: ThemingProps<'Table'>['size']
  cursor?: string
  layout?: TableProps['layout']
  colorScheme?: ThemingProps<'Table'>['colorScheme']
}

export default function DataTable<Data extends object, Value = unknown>({
  data,
  columns,
  onRowClick,
  isLoading,
  variant = 'simple',
  size = 'md',
  cursor = 'default',
  colorScheme = 'normal',
  layout,
}: DataTableProps<Data, Value>) {
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  })

  const containerRef = useRef<HTMLDivElement | null>(null)
  const dimensions = useDimensions(containerRef, true)

  const { rows } = table.getRowModel()

  return (
    <TableContainer
      ref={containerRef}
      overflowX={rows.length === 0 ? 'hidden' : 'auto'}
    >
      <Table
        size={size}
        variant={variant}
        cursor={cursor}
        colorScheme={colorScheme}
      >
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const meta: any = header.column.columnDef.meta
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row, index) => (
            <Tr
              key={row.id}
              onClick={e => onRowClick && onRowClick(row.original, index, e)}
            >
              {row.getVisibleCells().map(cell => {
                const meta: any = cell.column.columnDef.meta
                return (
                  <Td key={cell.id} isNumeric={meta?.isNumeric}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                )
              })}
            </Tr>
          ))}
          {rows.length === 0 ? (
            <Tr>
              <Td
                colSpan={table.getAllColumns().length}
                px='0'
                py='14'
                bg='none'
                border='none'
              >
                <Box
                  w={dimensions?.borderBox.width}
                  visibility={
                    dimensions?.borderBox.width ? 'visible' : 'hidden'
                  }
                  h='20'
                  position='sticky'
                  left='0'
                >
                  {isLoading ? (
                    <Center w='full' h='full'>
                      <Spinner
                        thickness='4px'
                        emptyColor='gray.200'
                        color='prim.400'
                        size='xl'
                      />
                    </Center>
                  ) : (
                    <AtomEmpty />
                  )}
                </Box>
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
