import type { PayloadAction } from '@reduxjs/toolkit'
import {
  createDraftSafeSelector,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit'

import type { RootState } from '../app/store'
import LoginModalTab from '../login/LoginModalTab'
import { isLoginModalTab } from '../login/utils'
import { WalletModalTab } from '../wallet/walletSlice'
import type { LoginModalData, ModalStateMap } from './types'
import { GlobalModalKey } from './types'

export type OverlayPayloadAction<T> = {
  overlayId: string
  args?: T
}

export type ModalAction<T = unknown> = {
  key: GlobalModalKey
  data?: T
  extra?: any
}

export function isLoginModalData(value: unknown): value is LoginModalData {
  return (
    typeof value === 'object' &&
    value !== null &&
    'index' in value &&
    isLoginModalTab(value.index)
  )
}

const initialState: ModalStateMap = {
  [GlobalModalKey.Login]: {
    open: false,
    data: {
      index: LoginModalTab.SignIn,
      tab: 'account',
      defaultInput: '',
    },
  },
  [GlobalModalKey.ResetPassword]: {
    open: false,
  },
  [GlobalModalKey.Coupon]: {
    open: false,
  },
  [GlobalModalKey.LuckyWheel]: {
    open: false,
  },
  [GlobalModalKey.LoginReward]: {
    open: false,
  },
  //new login reward modal
  [GlobalModalKey.NewLoginReward]: {
    open: false,
  },

  [GlobalModalKey.RewardSuccess]: {
    open: false,
    data: 0,
  },
  [GlobalModalKey.RechargeGuide]: {
    open: false,
  },
  [GlobalModalKey.RegionLimit]: {
    open: false,
    data: false,
  },
  [GlobalModalKey.Wallet]: {
    open: false,
    data: {
      defaultActivedTabKey: WalletModalTab.recharge,
    },
  },
  [GlobalModalKey.Confirm]: {
    open: false,
    data: {
      title: '',
      content: '',
    },
  },
  [GlobalModalKey.RedeemResult]: {
    open: false,
  },
  [GlobalModalKey.TgModal]: {
    open: false,
    extra: {
      // 是否是用户手动触发
      isManu: false,
    },
  },
  [GlobalModalKey.SplashAds]: {
    open: false,
    data: {},
    extra: {
      // 是否是用户手动触发
      isManu: false,
    },
  },
  [GlobalModalKey.Announce]: {
    open: false,
    data: {},
    extra: {
      isManu: false,
    },
  },
  [GlobalModalKey.Loading]: {
    open: false,
  },
  [GlobalModalKey.AddToHomeScreen]: {
    open: false,
  },
  [GlobalModalKey.AppDownloadDiversion]: {
    open: false,
  },
  [GlobalModalKey.IntroductionToDownloadModal]: {
    open: false,
    data: {},
  },
  [GlobalModalKey.WrongPassword]: {
    open: false,
    data: {
      account: '',
    },
  },
  [GlobalModalKey.RechargePackage]: {
    open: false,
    extra: {
      isManu: false,
    },
  },
  [GlobalModalKey.ActTips]: {
    open: false,
  },
  [GlobalModalKey.IOSSecurityAwareness]: {
    open: false,
  },
  [GlobalModalKey.QrCode]: {
    open: false,
  },
  [GlobalModalKey.LoginAndSignUpConfigError]: {
    open: false,
  },

  [GlobalModalKey.ServiceList]: {
    open: false,
  },
  // 新的充值活动弹窗
  [GlobalModalKey.newRechargeActModal]: {
    open: false,
    data: {},
  },

  // new login reward
  [GlobalModalKey.newLoginRewardModal]: {
    open: false,
  },

  [GlobalModalKey.LuckyWheelActivity]: {
    open: false,
    data: {
      isOpen: false,
    },
  },
  [GlobalModalKey.MoneyRain]: {
    open: false,
    data: {
      modalType: 'rules',
      isOpen: false,
    },
    extra: {
      isManu: false,
    },
  },
  [GlobalModalKey.SharesModal]: {
    open: false,
  },
  [GlobalModalKey.FavoriteLimit]: {
    open: false,
  },
  [GlobalModalKey.MPesaModal]: {
    open: false,
  },
  [GlobalModalKey.BindInfoModal]: {
    open: false,
  },
  [GlobalModalKey.Search]: {
    open: false,
  },
  [GlobalModalKey.GoogleRecpatcha]: {
    open: false,
    data: {
      type: 'register',
    },
  },
  [GlobalModalKey.GcashPaymentModal]: {
    open: false,
  },
  [GlobalModalKey.BankruptRechargeModal]: {
    open: false,
  },
  [GlobalModalKey.DrawActivityModal]: {
    open: false,
  },
  [GlobalModalKey.WalletGiftTips]: {
    open: false,
  },
  [GlobalModalKey.WalletTutorial]: {
    open: false,
    data: {},
  },
  [GlobalModalKey.WalletConfirmForm]: {
    open: false,
  },
  [GlobalModalKey.UpgradeVipGuide]: {
    open: false,
  },
  hiding: {},
  // modalFormation: [],
}

export const NEED_CLEAR_DATA_KEY = [
  GlobalModalKey.SplashAds,
  GlobalModalKey.Announce,
  GlobalModalKey.TgModal,
  GlobalModalKey.MoneyRain,
  GlobalModalKey.RechargePackage,
]

export const globalModalSlice = createSlice({
  name: 'globalModal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalAction>) => {
      const key = action.payload.key
      state[key].open = true
      if (action.payload.data) {
        state[key].data = action.payload.data
      }

      if (action.payload.extra) {
        state[key].extra = action.payload.extra || {}
      } else {
        state[key].extra = {}
      }
      // state.modalFormation.unshift(key)
      // 哪些modal重新打开需要清空数据
      // if (!action.payload.data && NEED_CLEAR_DATA_KEY.includes(key)) {
      //   state[key].extra = {}
      // }
    },
    closeModal: (state, action: PayloadAction<ModalAction>) => {
      const key = action.payload.key
      state[key].open = false
      // state.modalFormation = state.modalFormation.filter(item => item !== key)
    },
    setModalData: (state, action: PayloadAction<ModalAction>) => {
      const key = action.payload.key
      const data = action.payload.data || {}
      state[key].data = data
    },
    show: (state, action: PayloadAction<OverlayPayloadAction<unknown>>) => {
      const { overlayId, args } = action.payload
      state[overlayId] = args !== undefined ? args : true

      state.hiding[overlayId] = false
    },

    hide: (
      state,
      action: PayloadAction<{
        overlayId: string
        force?: boolean
      }>,
    ) => {
      const { overlayId, force } = action.payload

      if (force) {
        state[overlayId] = false
        state.hiding[overlayId] = false
      } else {
        state.hiding[overlayId] = true
      }
    },
  },
})

const selectSelf = (state: RootState) => state
export const selectModal = createDraftSafeSelector(
  selectSelf,
  (state: RootState) => state.globalModal,
)

// export const selectModalFormation = (state: RootState) =>
//   selectModal(state).modalFormation

export const createModalSelector = (key: string) => (state: RootState) =>
  state.globalModal[key as GlobalModalKey]

export const { openModal, closeModal, setModalData, show, hide } =
  globalModalSlice.actions

export const openWallet = (tab?: 'recharge' | 'withdraw') =>
  openModal({
    key: GlobalModalKey.Wallet,
    data: { defaultActivedTabKey: tab ?? 'recharge' },
  })

export const globalModalReducer = globalModalSlice.reducer

export const selelctGlobalLoadingIsOpen = createSelector(
  selectModal,
  modals => modals[GlobalModalKey.Loading]?.open,
)

export const selectLoginModalData = createSelector(
  createModalSelector(GlobalModalKey.Login),
  modal => modal.data,
)
// 公告弹窗信息
export const selelctAnnounceModalInfo = createSelector(
  selectModal,
  modals => modals[GlobalModalKey.Announce],
)

// 推荐弹窗信息
export const selelctRecommendModalInfo = createSelector(
  selectModal,
  modals => modals[GlobalModalKey.SplashAds],
)
