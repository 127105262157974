import { memo } from 'react'

import { getVariable } from '@/common/env'
import { isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import { useGetHomeGameListQuery } from '@/modules/game/api'
import { useRechargeResult, useUserAuth } from '@/utils/hooks'
import { getFeatures } from '@/utils/tools'
import { features, sdk } from '@/websites/current/property.json'

import { useGetRechargeActListQuery } from '../activity/activityApi'
import { useGetLuckyWheelConfigQuery } from '../activity/lucky-wheel/luckyWheelApi'
import useMoneyRainRequest from '../activity/money-rain/hooks/useMoneyRainRequest'
import {
  useCurrencyFixedRateInfoQuery,
  useCurrencyRateInfoQuery,
} from '../bind-currency/currencyApi'
import { useGetMyFirstLoginRewardQuery } from '../guide-event/guideEventApi'
import { useGetLoginAndSignUpConfigQuery } from '../login/New/newLoginAndSignUpApi'
import {
  HostTag,
  useGetHostByTagQuery,
} from '../multiple-domains/multiple-domains-api'
import {
  useGetAccountsInfoQuery,
  useGetLoginAndSignUpConfigV2Query,
} from '../new-login/loginApi'
import { useGetReferralInviteConfigQuery } from '../new-referral/api'
import {
  useGetUserVipInfoQuery,
  useGetVipConfigListQuery,
  useGetVipDailyRewardSwitchQuery,
  useGetVipRewardListQuery,
} from '../new-vip/newVipApi'
import { useGetMessagesQuery } from '../notification/api'
import { useGetAgentCfgQuery } from '../referral/api'
import {
  useGetIsSaleSmartlyEnableQuery,
  useServiceEntryConfigQuery,
} from '../service-list/api'
import { useGetUserInfoQuery } from '../user/userApi'
import { useRaffleConfigQuery } from '../wheel-modal/wheelModalApi'
// import { useGetFeatSwitchListQuery } from './appApi'

const REFERRAL_REFUND_FROM_API = getVariable('REFERRAL_REFUND_FROM_API')
const NEED_EXCHANGE_CURRENCY = getVariable('NEED_EXCHANGE_CURRENCY')
const FIRST_LOGIN_REWARD = getVariable('FIRST_LOGIN_REWARD')
const rechargeActivityV2 = isFeatureEnabled('rechargeActivityV2')
const loginAndSignUpV3 = getFeatures('loginAndSignUpV3')

function _QueriesOnAppInit() {
  const [userId, token] = useUserAuth()
  useGetVipConfigListQuery(userId)
  useRaffleConfigQuery()
  useGetAgentCfgQuery(
    { currency: getCurrencyCode() as string },
    { skip: !REFERRAL_REFUND_FROM_API },
  )
  useGetReferralInviteConfigQuery(undefined, { skip: !features?.referralNew })
  useGetUserInfoQuery({ token, user_id: userId }, { skip: !token || !userId })
  useGetAccountsInfoQuery(undefined, {
    skip: !token || !userId || !isFeatureEnabled('loginAndSignUpV3'),
  })
  useGetUserVipInfoQuery(
    { token, user_id: userId },
    { skip: !token || !userId },
  )

  useGetVipRewardListQuery(
    { token, user_id: userId },
    { skip: !token || !userId },
  )
  useCurrencyRateInfoQuery(
    { user_id: userId, token: token },
    { skip: !NEED_EXCHANGE_CURRENCY },
  )

  useCurrencyFixedRateInfoQuery({}, { skip: !NEED_EXCHANGE_CURRENCY })

  useRechargeResult()

  useGetMyFirstLoginRewardQuery(undefined, {
    skip:
      !FIRST_LOGIN_REWARD ||
      ((!token || !userId) && !!isFeatureEnabled('newAppLoginReward')),
  })

  useGetRechargeActListQuery(
    { token, user_id: userId },
    { skip: !rechargeActivityV2 },
  )
  useGetLoginAndSignUpConfigQuery(undefined, {
    skip: loginAndSignUpV3,
  })
  useGetLoginAndSignUpConfigV2Query(undefined, {
    skip: !loginAndSignUpV3,
  })
  useGetMessagesQuery(
    { uid: userId, user_id: userId, token },
    { skip: !token || !userId },
  )

  useGetIsSaleSmartlyEnableQuery(undefined, {
    skip: !sdk.SALE_SMARTLY,
  })

  useGetHostByTagQuery(
    { tag_host_map: { [HostTag.ShareEntry]: 1 } },
    { skip: !token || !userId },
  )

  useGetLuckyWheelConfigQuery(
    {
      token,
      user_id: userId,
    },
    {
      skip: !isFeatureEnabled('luckyWheel'),
    },
  )

  useServiceEntryConfigQuery(undefined, {
    skip: !isFeatureEnabled('newServiceList'),
  })

  useMoneyRainRequest()

  /** 每日签到开关 */
  useGetVipDailyRewardSwitchQuery()

  /** 首页游戏展示接口 */
  useGetHomeGameListQuery(undefined, {
    skip: !isFeatureEnabled('gameApiReplaceJson'),
  })

  /** 特性开关列表 */
  // useGetFeatSwitchListQuery(undefined)

  return null
}

const QueriesOnAppInit = memo(_QueriesOnAppInit)

export default QueriesOnAppInit
