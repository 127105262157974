import {
  Avatar,
  Box,
  HStack,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { createColumnHelper } from '@tanstack/react-table'
import { useInViewport } from 'ahooks'
import type { FC } from 'react'
import React, { createRef, memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getVariable } from '@/common/env'
import AtomTable from '@/components/atom-table/AtomTable'
import type { GameId } from '@/modules/inhouse/gameIds'
import { displayGameName } from '@/modules/inhouse/gameIds'
import { getIcon } from '@/utils/get-icon'
import useCustomizedStyle from '@/utils/hooks/useCustomizedStyle'
import { formatLocalMoney } from '@/utils/tools'
import appConfig from '@/websites/current/pages/layout/app-config.json'
import { colorMode } from '@/websites/current/property.json'

import {
  useGetBonusInfoQuery,
  useGetLuckInfoQuery,
  useGetWinInfoQuery,
} from './api'
import { GameIconText } from './GameIconText'
import RankingHeadBox from './RankingHeadBox'
import type { RankRecord } from './type'

const SHOW_WEEKLY_BONUS = getVariable('SHOW_WEEKLY_BONUS') || false

const columnsHelper = createColumnHelper<RankRecord>()

const Ranking: FC = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const { t } = useTranslation()

  const styles = useCustomizedStyle(
    'TableWithTabs',
    {
      tablistContainer: {},
      tablist: {},
      tablepanel: {
        borderRadius: 'lg',
        pb: '1',
        px: '3',
        overflow: 'hidden',
        position: 'relative',
      },
    },
    ['tablepanel', 'tablist', 'tablistContainer'],
  )

  const winInfoQuery = useGetWinInfoQuery(10, {
    skip: !(!SHOW_WEEKLY_BONUS && tabIndex === 0),
  })

  const luckyInfoQuery = useGetLuckInfoQuery(10, {
    skip: tabIndex === 0,
  })

  const weeklyBonus = useGetBonusInfoQuery('weekly', {
    skip: !SHOW_WEEKLY_BONUS || tabIndex !== 0,
  })

  const dailyBonus = useGetBonusInfoQuery('daily', {
    skip: !SHOW_WEEKLY_BONUS || tabIndex !== 1,
  })

  const handleData = data => {
    return data.filter(item => !!item.likeGame)
  }

  const onTabChange = (index: number) => setTabIndex(index)

  const numberCellRender = (n: number, suffix: undefined | number | void) => (
    <Text textStyle='h6' color='text.moneyPrim'>
      {suffix ? Number(n).toFixed(2) : n}
    </Text>
  )

  const moneyCellRender = (n: number) => (
    <Text textStyle='h6' color='text.money'>
      {formatLocalMoney(n)}
    </Text>
  )

  const gameCellRender = (
    game: GameId | string,
    gameId?: string | undefined | null,
  ) => (
    <GameIconText game={game} gameId={gameId} asLink>
      {displayGameName(game)}
    </GameIconText>
  )

  const userNameWithAvatarCellRender = (userName: string, index: number) => (
    <HStack justifyContent='center'>
      <Avatar src={appConfig.builtInAvatars[index % 6]} size={['sm', 'md']} />
      <Text>{userName}</Text>
    </HStack>
  )

  const rankIndexColum = columnsHelper.accessor('ranking', {
    header: t('NEW_HOME_TABLE_NAV_01').toString(),
    cell: ({ row: { index } }) => {
      const isWhiteBg = colorMode === 'light'
      const colorsList = isWhiteBg
        ? ['seco.500', 'gray.600', 'tert.500']
        : ['four.500', 'prim.300', 'tert.500']

      const getIndexFontColor = (i: number) => colorsList[i] ?? 'text.accent'
      return <Text color={getIndexFontColor(index)}>{index + 1}</Text>
    },
  })

  const likeGameColumn = columnsHelper.accessor('likeGame', {
    header: () => (
      <Text w='32' mx='auto'>
        {t('NEW_HOME_TABLE_NAV_02').toString()}
      </Text>
    ),
    cell: info =>
      gameCellRender(
        info.getValue() as string,
        info?.row?.original?.likeGameId || '',
      ),
  })

  const gameColumn = columnsHelper.accessor('game', {
    header: () => (
      <Text w='32' mx='auto'>
        {t('NEW_HOME_TABLE_NAV_02').toString()}
      </Text>
    ),
    cell: info =>
      gameCellRender(
        info.getValue() as string,
        info?.row?.original?.game_id || '',
      ),
  })

  const userNameColumn = columnsHelper.accessor('userName', {
    header: t('NEW_HOME_TABLE_NAV_03').toString(),
    cell: info =>
      userNameWithAvatarCellRender(info.getValue() as string, info.row.index),
  })

  const winCountColumn = columnsHelper.accessor('winCount', {
    header: t('NEW_HOME_TABLE_NAV_04').toString(),
    cell: info => numberCellRender(info.getValue() as number),
  })

  const multiplierColumn = columnsHelper.accessor('multiplier', {
    header: t('NEW_HOME_TABLE_NAV_06').toString(),
    cell: info => numberCellRender(info.getValue() as number, 2),
  })

  const amountColumn = columnsHelper.accessor('amount', {
    header: t('NEW_HOME_TABLE_NAV_05').toString(),
    cell: info => moneyCellRender(info.getValue() as number),
  })

  const payoutColumn = columnsHelper.accessor('payout', {
    header: t('NEW_HOME_TABLE_NAV_05').toString(),
    cell: info => moneyCellRender(info.getValue() as number),
  })

  const luckyInfoTableElem = (
    <AtomTable
      data={luckyInfoQuery?.data?.data ?? []}
      columns={[
        rankIndexColum,
        gameColumn,
        userNameColumn,
        multiplierColumn,
        payoutColumn,
      ]}
      variant='borderless'
      isLoading={luckyInfoQuery.isFetching}
    />
  )

  const tabs: {
    key: string
    text: string
    icon: string
    panelContent: React.ReactNode
  }[] = SHOW_WEEKLY_BONUS
    ? [
        {
          key: 'weekly_bonus',
          text: 'RANKING_BOUNS_NAV_ALL_WINS',
          icon: 'Cup',
          panelContent: (
            <AtomTable
              data={weeklyBonus?.data?.data ?? []}
              columns={[rankIndexColum, userNameColumn, amountColumn]}
              variant='borderless'
              isLoading={weeklyBonus.isFetching}
            />
          ),
        },
        {
          key: 'daily_bonus',
          text: 'NEW_HOME_RANK_NAV_01',
          icon: 'Medal',
          panelContent: (
            <AtomTable
              data={dailyBonus?.data?.data ?? []}
              columns={[rankIndexColum, userNameColumn, amountColumn]}
              variant='borderless'
              isLoading={dailyBonus.isFetching}
            />
          ),
        },
        {
          key: 'Luck_bonus',
          text: 'NEW_HOME_RANK_NAV_02',
          icon: 'Luck',
          panelContent: luckyInfoTableElem,
        },
      ]
    : [
        {
          key: 'win today',
          text: 'NEW_HOME_RANK_NAV_01',
          icon: 'Ranking',
          panelContent: (
            <AtomTable
              data={handleData(winInfoQuery?.data?.data ?? [])}
              columns={[
                rankIndexColum,
                userNameColumn,
                winCountColumn,
                amountColumn,
                likeGameColumn,
              ]}
              variant='borderless'
              isLoading={winInfoQuery.isFetching}
            />
          ),
        },
        {
          key: 'Luck jackpot',
          text: 'NEW_HOME_RANK_NAV_02',
          icon: 'Luck',
          panelContent: luckyInfoTableElem,
        },
      ]

  return (
    <Tabs
      variant='capsule-shape'
      index={tabIndex}
      onChange={onTabChange}
      isLazy={true}
    >
      <RankingHeadBox>
        <TabList>
          {tabs.map(({ key, text, icon }, index) => (
            <Tab key={key} onClick={() => onTabChange(index)}>
              <Icon as={getIcon(icon)} boxSize='20px' mr='2' />
              <Text noOfLines={1} textOverflow='ellipsis'>
                {t(text)}
              </Text>
            </Tab>
          ))}
        </TabList>
      </RankingHeadBox>
      <TabPanels>
        {tabs.map(({ key, panelContent }) => (
          <TabPanel sx={styles.tablepanel} key={key}>
            {panelContent}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

const MemoRanking = memo(Ranking)

export default function RankWrap() {
  const ref = createRef<HTMLDivElement>()
  const [inView] = useInViewport(ref)
  const [exist, setExist] = useState(!!inView)

  useEffect(() => {
    if (inView && !exist) {
      setExist(true)
    }
  }, [inView, exist])

  return <Box ref={ref}>{exist && <MemoRanking />}</Box>
}
