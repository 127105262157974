import {
  Box,
  Flex,
  HStack,
  Link,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { AtomSectionHeading } from '@/components/atom-section-heading/AtomSectionHeading'
import type {
  GameListItem,
  ManulacturerListItem,
} from '@/modules/game/gameInterface'

import GameCard from '../../../units/gamecard/AtomGameCard'
import { GameGrid } from '../../all-games/GameGrid'
import { DEFAULT_GAME_VIEW_OPTION, GRID_COLUMNS } from '../../constants'
import { BuiltInGameLabel } from '../../home-games/types'
import GameCardRender from '../GameCardRender'
import { ProviderItem } from '../ProviderListwiper'
import { HeaderRight } from './DesktopGroupGame'
import { FadingBox } from './FadingBox'
import type { GameViewOption, GroupGameProps } from './GroupGameProps'

const VIEW_ALL_GAMES_IMG = '/assets/images/about-icon/games/view_all_img.png'
const VIEW_ALL_GAMES_HORIZONTA_IMG =
  '/assets/images/about-icon/games/view_all_horizontal_img.png'

function split(arr: any[], chunkSize: number) {
  const result = []
  const numChunks = Math.ceil(arr.length / chunkSize)

  for (let i = 0; i < numChunks; i++) {
    result.push(arr.slice(i * chunkSize, (i + 1) * chunkSize))
  }

  return result
}

function handleSortGame(arr: any[], chunkSize: number) {
  return split(arr, chunkSize)
    ?.map(item => [
      split(item, Math.ceil((item?.length || 0) / 2))?.[0] || [],
      split(item, Math.ceil((item?.length || 0) / 2))?.[1] || [],
    ])
    ?.reduce(
      (arr, cur) => {
        return [
          [...(arr?.[0] || []), ...(cur[0] || [])],
          [...(arr?.[1] || []), ...(cur[1] || [])],
        ]
      },
      [[], []],
    )
}

export function MobileGroupGame({
  datasource,
  type,
  label,
  onItemClick,
  viewOption = DEFAULT_GAME_VIEW_OPTION,
}: GroupGameProps) {
  const { t } = useTranslation()
  const { name, icon, data, providerSort } = datasource
  const showViewAll = data.length > 1
  const itemNumberPerRow = useBreakpointValue(GRID_COLUMNS) ?? GRID_COLUMNS.base
  const games = useMemo(
    () =>
      data?.length > 12 && viewOption.rowsPerView === 2
        ? data.slice(0, 11)
        : data.slice(0, 12),
    [data, viewOption],
  )
  const chunkSize = useBreakpointValue({
    base: 6,
    sm: 8,
    md: 10,
    lg: 12,
  })

  const dobuleRowGames = useMemo(() => {
    if (
      (type === 2 && providerSort?.length >= (chunkSize || 12)) ||
      (type === 1 && data?.length >= (chunkSize || 12))
    ) {
      return handleSortGame(type === 2 ? providerSort : games, chunkSize || 12)
    }
    const sliceData = type === 2 ? providerSort : games
    return [
      sliceData?.slice(0, (chunkSize || 12) / 2),
      sliceData?.slice((chunkSize || 12) / 2),
    ]
  }, [type, providerSort, chunkSize, data, games])

  const handleGameListRender = (
    gameList: GameListItem[],
    option: GameViewOption,
    showViewAll: boolean,
  ) => {
    const { coverOrientation = 'portrait' } = option || {}
    const cardContainerStyles = {
      width: ['29%', '23%', '18%', '14%'],
      flexShrink: '0',
    }
    return (
      <Flex width='100%' gap='2.5'>
        {gameList.map(item => {
          return (
            <Box {...cardContainerStyles} key={item.game_id + item.game_name}>
              <GameCardRender
                info={item}
                onClick={() => onItemClick?.(item)}
                orientation={coverOrientation}
              />
            </Box>
          )
        })}
        {showViewAll && data?.length > 12 && (
          <Link
            as={RouterLink}
            to={`/game/all?cate=${type}&label=${label}`}
            display='block'
            {...cardContainerStyles}
          >
            <GameCard
              src={
                coverOrientation === 'portrait'
                  ? VIEW_ALL_GAMES_IMG
                  : VIEW_ALL_GAMES_HORIZONTA_IMG
              }
              orientation={coverOrientation}
            />
          </Link>
        )}
      </Flex>
    )
  }

  const handleProviderListRender = (providerList: ManulacturerListItem[]) => {
    const cardContainerStyles = {
      width: ['29%', '23%', '18%', '14%'],
      flexShrink: '0',
    }
    return (
      <Flex width='100%' gap='2.5'>
        {providerList.map(item => {
          return (
            <Box
              {...cardContainerStyles}
              key={`mobile_provider_row_${item.id}`}
              bgColor='gray.400'
              borderRadius={8}
              h='64px'
              overflow='hidden'
            >
              <ProviderItem provider={item} />
            </Box>
          )
        })}
      </Flex>
    )
  }

  const contentRender = (option: GameViewOption) => {
    const {
      rowsPerView = 1,
      loadMoreType = 1,
      coverOrientation = 'portrait',
    } = option || {}

    if (loadMoreType === 1 || type === 2) {
      return (
        <FadingBox>
          <Box overflowX='scroll'>
            {rowsPerView === 1 && type !== 2 ? (
              handleGameListRender(games, option, showViewAll)
            ) : (
              <>
                {type === 2
                  ? handleProviderListRender(dobuleRowGames?.[0] || [])
                  : handleGameListRender(
                      dobuleRowGames?.[0] || [],
                      option,
                      showViewAll &&
                        dobuleRowGames?.[0]?.length ===
                          dobuleRowGames?.[1]?.length,
                    )}
                {!!dobuleRowGames?.[1]?.length && (
                  <Box mt={3}>
                    {type === 2
                      ? handleProviderListRender(dobuleRowGames?.[1] || [])
                      : handleGameListRender(
                          dobuleRowGames?.[1] || [],
                          option,
                          showViewAll &&
                            dobuleRowGames?.[0]?.length >
                              dobuleRowGames?.[1]?.length,
                        )}
                  </Box>
                )}
              </>
            )}
          </Box>
        </FadingBox>
      )
    } else {
      return (
        <GameGrid
          data={data}
          onCardClick={onItemClick}
          pageSize={itemNumberPerRow * rowsPerView}
          coverOrientation={coverOrientation}
        />
      )
    }
  }

  return (
    <VStack alignItems='stretch' spacing={['3', '3', '5']}>
      <HStack justifyContent='space-between' spacing='4'>
        <AtomSectionHeading icon={icon} useImage={true}>
          {t(name)}
        </AtomSectionHeading>
        {viewOption.loadMoreType === 1 && (
          <HeaderRight
            allGameHref={`/game/all?cate=${
              type === 2 ? BuiltInGameLabel.Manufacturer : type
            }&label=${label}`}
            gameNumber={data?.length || providerSort?.length}
          />
        )}
      </HStack>
      {contentRender(viewOption)}
    </VStack>
  )
}
