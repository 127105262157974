import type { GamePlatform } from './contants'
import type {
  CategoryLabelListItem,
  GameListItem,
  ManulacturerListItem,
  SortGame,
} from './gameInterface'

/**
 * 筛选出未下线的游戏 && 根据是否维护进行排序
 * @param _game_list GameListItem[]
 * @returns GameListItem[]
 */
export function sortGame(_game_list: GameListItem[]) {
  _game_list = _game_list.slice().filter(item => item.game_status === 1)
  const maintGameList = _game_list
    .filter(item => item.is_under_maint === 1)
    .sort((a, b) => a.sort_weight - b.sort_weight)
  const unMaintGameList = _game_list
    .filter(item => item.is_under_maint !== 1)
    .sort((a, b) => a.sort_weight - b.sort_weight)
  const returnList = [...unMaintGameList, ...maintGameList]
  return returnList
}

/**
 * 过滤掉未开启的厂商 & 允许游戏维护（已经排序）
 * @param data
 * @returns
 */
export function filterAndSortManu(manulacturer_list: ManulacturerListItem[]) {
  return manulacturer_list
    .filter(item => {
      return item.is_enabled === 1 && item.display_games === 1
    })
    .map(item => {
      return {
        ...item,
        supportCurrency: ['ALL'],
        data: [] as GameListItem[],
        name: item.name,
        icon: item.icon,
        type: item.name,
      }
    })
    .sort((a, b) => a.sort_weight - b.sort_weight)
}

/**
 *  根据分类排序游戏
 */
export function showGameByCateSort(
  list: GameListItem[],
  sort_list: SortGame[],
) {
  const endList = [] as GameListItem[]
  let _sort_list = [...sort_list]
  _sort_list = _sort_list.sort((a, b) => a.sort_weight - b.sort_weight)
  _sort_list.forEach(item => {
    const findGame = list.find(d => d.game_id === item.game_id)
    if (findGame?.game_id) {
      endList.push(findGame)
    }
  })
  return endList
}

/**
 * 根据标签展示游戏
 */
export function showCateByCateSort(list: CategoryLabelListItem[]) {
  list.forEach(item => {
    item.data = showGameByCateSort(item.data, item.game_sort)
  })

  return list
}

export function getGamePlatform(game: GameListItem) {
  const expand = game?.expand || {}
  const platform = expand.game_platform as GamePlatform
  return platform.includes('hillingo') ? 'hillingo' : platform
}

export function isInhousePlatform(platform: string) {
  return platform === 'inhouse'
}
