import type { ComponentStyleConfig } from '@chakra-ui/react'

import { NAME } from '@/modules/game/ranking/RankingHeadBox'

const componentStyle: ComponentStyleConfig = {
  baseStyle: {
    '--bg-gradient': 'linear-gradient(0deg, #F2E9CB 0%, #EAD7A4 100%)',
  },
}

export const name = NAME
export default componentStyle
