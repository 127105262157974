import { VStack } from '@chakra-ui/react'
import { memo } from 'react'

import { getVariable } from '@/common/env'
import { FeatureToggle, isFeatureEnabled } from '@/common/feature'
import { getCurrencyCode } from '@/common/locale/currency'
import Advantage from '@/modules/advantage'
import FloatButtons from '@/modules/app-layout/FloatButtons'
import HomeGames from '@/modules/game/logic/home-games/HomeGames'
import HomeApiGames from '@/modules/game/logic/HomeApiGames'
import Ranking from '@/modules/game/ranking'
import property from '@/websites/current/property.json'

import FloatCenterButton from '../app-layout/FloatCenterButton'
import ErrorBoundary from '../error/ErrorBoundary'
import { CollectionGamesProvider } from '../my-games/useGameGrid'
import SearchTigger from '../search-game/SearchTigger'
import HeroSection, {
  BottomH24HoursRank,
  MobileV24HoursRank,
} from './HeroSection'

import GameRecordsAndCollection from './GameRecordsAndCollection'
import withIsUserLogin from './withIsUserLogin'
import { isFeatureEnabledV2 } from '@/common/featureSwiitch'
const EnhancedGameRecordsAndCollection = withIsUserLogin(
  GameRecordsAndCollection,
)

const CURRENCY = getCurrencyCode()

const { locale, features } = property

const HIDE_HOME_RANK = getVariable('HIDE_HOME_RANK')
function _Home() {
  const showRank =
    !HIDE_HOME_RANK || (HIDE_HOME_RANK && locale.currencies.includes(CURRENCY))

  return (
    <VStack alignItems='stretch' spacing='5' mt={{ base: '2.5', md: '5' }}>
      <FeatureToggle feature='gameSearch'>
        <SearchTigger colorScheme='gray' />
      </FeatureToggle>

      <ErrorBoundary>
        <CollectionGamesProvider>
          <HeroSection />

          {isFeatureEnabledV2('game_records_collections') && (
            <EnhancedGameRecordsAndCollection />
          )}

          <BottomH24HoursRank />

          {isFeatureEnabled('gameApiReplaceJson') ? (
            <HomeApiGames />
          ) : (
            <HomeGames />
          )}
        </CollectionGamesProvider>
      </ErrorBoundary>

      {isFeatureEnabled('advantagePage') ? (
        <VStack>
          <Advantage />
        </VStack>
      ) : null}

      <MobileV24HoursRank />

      {showRank && <Ranking />}
      {features.addToHomeScreenBtn ? <FloatCenterButton /> : null}
      <FloatButtons />
    </VStack>
  )
}

const Home = memo(_Home)

export default Home
