export interface Reward {
  /**
   * 打码量
   */
  bet_target?: number

  /**
   * 金币数量
   */
  num?: number

  /**
   * 货币类型
   */
  currency?: string

  /**
   * 奖励类型
   */
  type?: string
  /**
   * 奖励有效期
   */
  expire_day?: number
}

export interface Message {
  /**
   * 按钮文案
   */
  button_name?: string
  /**
   * 消息时间
   */
  created_at?: number
  /**
   * 消息guid
   */
  guid?: string
  /**
   * 是否已读
   */
  is_read?: number
  /**
   * 跳转/点击地址
   */
  jump_url?: string
  /**
   * 消息多语种key
   */
  message_key?: string
  /**
   * 消息参数
   */
  params?: Record<string, string | number>
  /**
   * 模板消息guid
   */
  template_guid?: string
  /**
   * 消息标题key
   */
  title_key?: string

  /**
   * 消息版本 0: 旧版(系统站内信) 1: 新版(自定义站内信)
   */
  message_version?: number

  /**
   * 是否已经领取奖励 0 未领取 1 已领取
   */
  is_claim_rewards?: number

  /**
   * 奖励列表
   */
  rewards?: Reward

  /**
   * 显示类型 0 无 1 跳转链接 2 奖励
   */
  show_type?: number

  /**
   * 消息内容
   */

  message?: Record<string, Record<string, string>>

  /**
   * 站内信存储有效期(按天)
   */
  effect_time?: number
}

export enum MessageShowType {
  None = 0,
  Jump = 1,
  Reward = 2,
}
