import { Box } from '@chakra-ui/react'
import React from 'react'

import { useAppSelector } from '@/modules/app/store'
import { HEADER_HEIGHT } from '@/modules/app-layout/constants'
import type {
  CategoryLabelListItem,
  GameShowDetail,
} from '@/modules/game/gameInterface'

import { selectRecommendGameGroups } from '../../../selectGameTabItems'
import { DEFAULT_GAME_VIEW_OPTION } from '../../constants'
import { trackHomeToGameEvt } from '../../trackHomeToGameEvt'
import type { GameViewOption } from './GroupGameProps'
import { GroupGames } from './GroupGames'

function getViewOption(group: CategoryLabelListItem): GameViewOption {
  const { game_show_detail } = group
  if (game_show_detail !== '') {
    try {
      const detail = JSON.parse(game_show_detail) as GameShowDetail
      return {
        rowsPerView: detail.home_view_row ?? 1,
        loadMoreType: detail.content_load_type ?? 1,
        coverOrientation:
          detail.game_show_type === 2 ? 'landscape' : 'portrait',
      }
    } catch (error) {
      // do nothing
    }
  }
  return DEFAULT_GAME_VIEW_OPTION
}

function RecommendGameGroups() {
  const recommendGameGroups = useAppSelector(selectRecommendGameGroups)

  return recommendGameGroups.map(group => {
    const id = group.label_id
    const viewOption = getViewOption(group)

    return (
      <Box key={group.label_id} position='relative'>
        <Box
          position='absolute'
          id={String(id)}
          top={`-${HEADER_HEIGHT}`}
          arir-label='group-anchor'
        />
        <GroupGames
          datasource={group}
          label={id}
          type={group.type}
          onItemClick={game => {
            if (group.type !== 2) {
              trackHomeToGameEvt(game, id)
            }
          }}
          viewOption={viewOption}
        />
      </Box>
    )
  })
}
const RecommendGameGroupsMemo = React.memo(RecommendGameGroups)
export default RecommendGameGroupsMemo
