import { ChakraProvider } from '@chakra-ui/react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect, useLayoutEffect } from 'react'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'

import { getVariable } from '@/common/env'
import { store } from '@/modules/app/store'
import { router } from '@/routes/root'
import theme from '@/styles/theme'
import globalToastOption from '@/styles/toast'
import FPSMonitor from '@/utils/atom-performance/FPSMonitor'
import { localStorage } from '@/utils/localStorage'

import ErrorBoundary from '../error/ErrorBoundary'

const googleClientId = getVariable('NEXT_PUBLIC_GOOGLE_CLICENT_ID') ?? ''

function App() {
  useLayoutEffect(() => {
    if (window) {
      window.close = () => null
    }
  }, [])

  useEffect(() => {
    FPSMonitor.getInstance().start()

    return () => {
      FPSMonitor.getInstance().destroy()
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('web_version', '1.0')
    window.addEventListener('vite:preloadError', event => {
      window.location.reload()
    })
  }, [])

  return (
    <Provider store={store}>
      <ChakraProvider theme={theme} toastOptions={globalToastOption}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <ErrorBoundary>
            <RouterProvider router={router} />
          </ErrorBoundary>
        </GoogleOAuthProvider>
      </ChakraProvider>
    </Provider>
  )
}

export default App
